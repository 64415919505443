import { chain, configureChains, createClient } from "wagmi";
import { publicProvider } from "wagmi/providers/public";
import { infuraProvider } from "wagmi/providers/infura";
import {
  getDefaultWallets,
  connectorsForWallets,
} from "@rainbow-me/rainbowkit";

const { chains, provider, webSocketProvider } = configureChains(
  process.env.NEXT_PUBLIC_ENABLE_TESTNETS === "false" ? [chain.mainnet] : [chain.goerli, chain.rinkeby],
  [
    infuraProvider({ apiKey: process.env.NEXT_PUBLIC_INFURA_ID }),
    publicProvider(),
  ]
);

export const rainbowChains = chains;

const { wallets } = getDefaultWallets({
  appName: "Photopia",
  chains,
});

const connectors = connectorsForWallets([...wallets]);

export const client = createClient({
  autoConnect: true,
  connectors,
  provider,
  webSocketProvider,
});
