import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

interface IActionContext {
  setLoading: Dispatch<SetStateAction<boolean>>;
  setIsTestnet: Dispatch<SetStateAction<boolean>>;
  setMintedMaxAmount: Dispatch<SetStateAction<boolean>>;
  setTransactionHash: Dispatch<SetStateAction<string>>;
  setOpenseaId: Dispatch<SetStateAction<string>>;
  setMounted?: Dispatch<SetStateAction<string>>;
  loading: boolean;
  isMounted: boolean;
  transactionHash: String;
  isTestnet: boolean;
  mintedMaxAmount: boolean;
  openseaId: string;
}

const ActionContext = createContext({} as IActionContext);

interface Props {
  children?: ReactNode;
}

export function ActionProvider({ children }: Props) {
  const [isMounted, setMounted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isTestnet, setIsTestnet] = useState(true);
  const [transactionHash, setTransactionHash] = useState("");
  const [openseaId, setOpenseaId] = useState("");
  const [mintedMaxAmount, setMintedMaxAmount] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, [])

  const providerValues: IActionContext = useMemo(
    () => ({
      loading,
      isMounted,
      isTestnet,
      transactionHash,
      openseaId,
      mintedMaxAmount,
      setIsTestnet,
      setLoading,
      setTransactionHash,
      setOpenseaId,
      setMintedMaxAmount
    }),
    [loading, isTestnet, mintedMaxAmount, transactionHash, isMounted, openseaId]
  );

  return (
    <ActionContext.Provider value={providerValues}>
      {children}
    </ActionContext.Provider>
  );
}

export function useActions() {
  return useContext(ActionContext);
}
