import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { ethers, utils } from "ethers";

import abi from "../config/contractAbi.json";
import { CONTRACTS } from "../config/contract";
import ToastMessage from "../components/ErrorAlert";
import { useActions } from "./useActions";
import { useRouter } from "next/router";
import { convertBigNumberToNumber } from "../helpers/helper";

interface IEthereumContext {
  isMetaMaskAvailable: boolean;
  isSaleActive: boolean;
  getWallet: () => Promise<any>;
  mint: () => Promise<any>;
  account: string;
  totalSupply: number;
}

const EthereumContext = createContext<IEthereumContext>({} as IEthereumContext);

export function EthereumProvider({ children }: any) {
  const router = useRouter();
  const {
    setLoading,
    setTransactionHash,
    setOpenseaId,
    setIsTestnet,
    setMintedMaxAmount,
  } = useActions();

  const [ethereumProvider, setEthereumProvider] = useState<any>(null);
  const [ethereumContract, setEthereumContract] = useState<any>(null);
  const [isMetaMaskAvailable, setMetaMaskAvailable] = useState(false);
  const [isSaleActive, setSaleState] = useState(false);
  const [account, setAccount] = useState("");
  const [totalSupply, setTotalSupply] = useState(0);

  function isEthereumAvailable() {
    const checkEthereum = !(
      typeof window !== "undefined" && typeof window.ethereum !== "undefined"
    );

    if (checkEthereum) {
      setMetaMaskAvailable(true);
      return true;
    }

    return false;
  }

  async function getProvider() {
    if (isEthereumAvailable()) {
      return;
    }

    const { ethereum } = window as any;
    
    setEthereumProvider(
      await new ethers.providers.Web3Provider(ethereum, "any")
    );
  }

  async function getContract() {
    try {
      const connectedContract = await new ethers.Contract(
        CONTRACTS.PH,
        abi,
        ethereumProvider
      );

      if (connectedContract) {
        setEthereumContract(connectedContract);
      }
    } catch (e) {
      ToastMessage({ error: e });
    }
  }

  async function handleAccountsChanged() {
    if (isEthereumAvailable()) {
      return;
    }

    // @ts-ignore
    window.ethereum.on("accountsChanged", ([accounts]: string[]) => {
      if (!accounts || typeof accounts === "undefined") {
        setAccount("");
      } else {
        setAccount(utils.getAddress(accounts));
      }
    });
  }

  const isUserConnectedToMainnet = async (chainId: string = "0x1") => {
    if (isEthereumAvailable()) {
      return;
    }

    const network = await ethereumProvider?.getNetwork();

    if (network?.chainId !== 1 || chainId !== "0x1") {
      setIsTestnet(true);
    } else {
      setIsTestnet(false);
    }
  };

  const handleChainChanged = async () => {
    if (isEthereumAvailable()) {
      return;
    }

    // @ts-ignore
    window.ethereum.on("chainChanged", (chainId: string) => {
      isUserConnectedToMainnet(chainId);
      router.reload();
    });
  };

  async function getCurrentConnectedAccount() {
    if (isEthereumAvailable()) {
      return;
    }
    if (ethereumProvider) {
      try {
        const signer = await ethereumProvider.getSigner();

        const currentaddress = await signer.getAddress();
        setAccount(utils.getAddress(currentaddress));
      } catch (e) {
        return e;
      }
    }
  }

  async function getWallet() {
    try {
      const network = await ethereumProvider.getNetwork();
      const [accounts] = await ethereumProvider.send("eth_requestAccounts", []);
      const signer = await ethereumProvider.getSigner();
      setAccount(utils.getAddress(accounts));
      
      return {
        network,
        accounts,
        signer,
      };
    } catch (e) {
      ToastMessage({ error: e });
      return e;
    }
  }

  async function mint() {
    try {
      const signer = await ethereumProvider.getSigner();
      const contractWithSigner = await ethereumContract.connect(signer);
      const minting = await contractWithSigner.mint();
      setLoading(true);
      setTransactionHash(minting.hash);
      const tx = await minting.wait();
      const convertedIdNumber = ethers.BigNumber.from(
        tx.events[0].args[2]
      ).toNumber();
      setOpenseaId(convertedIdNumber.toString());

      setLoading(false);
    } catch (e) {
      const parsedError = JSON.parse(JSON.stringify(e));

      if (parsedError.error) {
        ToastMessage({
          customMessage: parsedError.error ? parsedError.error.message : "",
        });
      } else {
        ToastMessage({
          error: e,
        });
      }

      setLoading(false);
    }
  }

  async function getIsSaleActive() {
    try {
      const saleState = await ethereumContract.IS_SALE_ACTIVE();

      setSaleState(saleState);
    } catch (e) {
      console.log(e);
      return e;
    }
  }

  async function getMaxMintedAmount(account: string) {
    try {
      const tokens = await ethereumContract.tokensMinted(account);

      setMintedMaxAmount(convertBigNumberToNumber(tokens) >= 1);
    } catch (e) {
      console.log(e);
      return e;
    }
  }

  async function getTotalSupply() {
    try {
      const supply = await ethereumContract.totalSupply();
      const convertedNumber = ethers.BigNumber.from(supply).toNumber();
      setTotalSupply(convertedNumber);
    } catch (e) {
      console.log(e);
      return e;
    }
  }

  useEffect(() => {
    isEthereumAvailable();
    getProvider();
    handleAccountsChanged();
    handleChainChanged();
  }, []);

  useEffect(() => {
    if (ethereumProvider) {
      getContract();
      isUserConnectedToMainnet();
      getCurrentConnectedAccount();
    }
  }, [ethereumProvider]);

  useEffect(() => {
    if (ethereumContract && ethereumProvider) {
      getIsSaleActive();
      getTotalSupply();
    }
  }, [ethereumContract, ethereumProvider]);

  useEffect(() => {
    if (ethereumContract && ethereumProvider && account) {
      getMaxMintedAmount(account);
    }
  }, [ethereumContract, ethereumProvider, account]);

  const providerValues: IEthereumContext = useMemo(
    () => ({
      isMetaMaskAvailable,
      getWallet,
      mint,
      account,
      isSaleActive,
      totalSupply,
    }),
    [isMetaMaskAvailable, mint, account, isSaleActive, totalSupply]
  );

  return (
    <EthereumContext.Provider value={providerValues}>
      {children}
    </EthereumContext.Provider>
  );
}

export function useEthereum() {
  return useContext(EthereumContext);
}
