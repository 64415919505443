import { Result } from "@ethersproject/abi";
import { BigNumber, ethers } from "ethers";

export const getFormattedAddress = (address: string): string => {
  const firstPart = address?.slice(0, 6);
  const lastPart = address?.slice(-6);
  return `${firstPart}...${lastPart}`;
};

export const convertBigNumberToNumber = (number: BigNumber | Result) => {
  return ethers.BigNumber.from(number).toNumber()
}