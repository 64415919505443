import "../styles/globals.css";
import "@rainbow-me/rainbowkit/styles.css";

import type { AppProps } from "next/app";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { EthereumProvider } from "../hooks/useEthereum";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import { ActionProvider } from "../hooks/useActions";
import { WagmiConfig } from "wagmi";
import { client, rainbowChains } from "../config/wagmi-config";
import { RainbowKitProvider } from "@rainbow-me/rainbowkit";
import { CustomWagmiProvider } from "../hooks/useWagmi";
import { useEffect } from "react";
import Head from "next/head";

function MyApp({ Component, pageProps }: AppProps) {
  gsap.registerPlugin(ScrollTrigger);
  ScrollTrigger.config({
    ignoreMobileResize: true,
  });

  return (
    <div>
      <WagmiConfig client={client}>
        <Head>
          <meta name="viewport" content="width=device-width, initial-scale=1" />
        </Head>
        <RainbowKitProvider modalSize="compact" chains={rainbowChains}>
          <ActionProvider>
            <CustomWagmiProvider>
              <EthereumProvider>
                <ToastContainer
                  position="top-right"
                  autoClose={8000}
                  hideProgressBar={false}
                  newestOnTop={false}
                  draggable={false}
                  closeOnClick
                  pauseOnHover
                />
                <Component {...pageProps} />
              </EthereumProvider>
            </CustomWagmiProvider>
          </ActionProvider>
        </RainbowKitProvider>
      </WagmiConfig>
    </div>
  );
}

export default MyApp;
