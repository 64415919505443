import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import abi from "../config/contractAbi.json";
import { CONTRACTS } from "../config/contract";
import ToastMessage from "../components/ErrorAlert";
import { useActions } from "./useActions";
import {
  useContractRead,
  useContractWrite,
  useNetwork,
  usePrepareContractWrite,
  useWaitForTransaction,
} from "wagmi";
import { convertBigNumberToNumber } from "../helpers/helper";
import { Result } from "ethers/lib/utils";

interface IWagmiContext {
  isSaleActive?: boolean;
  minting?: () => Promise<any>;
  totalSupply?: number;
  isMintLoading: boolean;
  isMintStarted: boolean;
  setSaleState: Dispatch<SetStateAction<Result>>;
  setTotalSupply: Dispatch<SetStateAction<number>>;
}

const WagmiContext = createContext<IWagmiContext>({} as IWagmiContext);

export function CustomWagmiProvider({ children }: any) {
  const { setLoading, setIsTestnet, setTransactionHash } = useActions();
  const { chain } = useNetwork();
  const [isSaleActive, setSaleState] = useState(false);
  const [totalSupply, setTotalSupply] = useState(0);

  const contractConfig = {
    addressOrName: CONTRACTS.PH,
    contractInterface: abi,
  };

  // const { config: contractWriteConfig } = usePrepareContractWrite({
  //   ...contractConfig,
  //   functionName: "mint",
  // });

  // const {
  //   data: mintData,
  //   write: mint,
  //   isLoading,
  //   isSuccess,
  //   error: mintError,
  // } = useContractWrite(contractWriteConfig);

  // const {
  //   data: txData,
  //   isSuccess: txSuccess,
  //   error: txError,
  // } = useWaitForTransaction({
  //   hash: mintData?.hash,
  //   onSuccess: () => setTransactionHash(String(mintData?.hash)),
  // });

  // async function minting() {
  //   try {
  //     const tx = await mint?.();

  //     setLoading(true);
  //     // @ts-ignore
  //     setTransactionHash(mintData?.hash);

  //     if(!txSuccess) {
  //       setLoading(false);
  //     }
  //   } catch (e) {
  //     console.log(e);
  //     setLoading(false);
  //     ToastMessage({ error: mintError });
  //   }
  // }

  // useEffect(() => {
  //   isMinting();
  //   // console.log(mintData?.wait())
  //   // console.log("loading", isMintLoading)
  //   // console.log("started", isMintStarted)
  // }, [mintData, isMintLoading, isMintStarted]);


  // useEffect(() => {
  //   if (totalSupplyData) {
  //     setTotalMinted(totalSupplyData.toNumber());
  //   }
  // }, [totalSupplyData]);

  useEffect(() => {
    if (chain || typeof chain !== "undefined") {
      setIsTestnet(chain?.name !== "Ethereum");
    }
  }, [chain]);

  const providerValues: any = useMemo(
    () => ({
      // minting,
      isSaleActive,
      totalSupply,
      setTotalSupply,
      setSaleState,
    }),
    [isSaleActive, totalSupply, setTotalSupply, setSaleState]
  );

  return (
    <WagmiContext.Provider value={providerValues}>
      {children}
    </WagmiContext.Provider>
  );
}

export function useWagmi() {
  return useContext(WagmiContext);
}
